import {OrderStatusEnum} from '@/components/orderSimply/utils/orderConfirmHelpers';

const getNetImport = (type, status, materialType, item, entry) => {
    if (
        status === OrderStatusEnum.CREATED ||
        status === OrderStatusEnum.CONFIRMED ||
        status === OrderStatusEnum.PARTIALLY_DELIVERED ||
        status === OrderStatusEnum.DELIVERED
    ) {
        return '-';
    }

    const hasEntryNationalBills = item.entries.some((e) =>
        e.bills.some((bill) => bill.jsonCfdi !== null)
    );

    if (!item.bill && hasEntryNationalBills) {
        const total = item.entries
            .flatMap((item) => item.bills)
            .reduce((carry, item) => +item.jsonCfdi.Total + carry, 0);
        return total.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
            maximumFractionDigits: 2,
        });
    }

    return '-';
};

const getBillData = (item, entry) => {
    let billingRef = item.billingRef;

    billingRef = entry?.bills[0]?.jsonCfdi
        ? entry?.bills[0]?.jsonCfdi?.Folio
        : entry?.bills[0]?.foreignInvoice?.invoiceNumber;

    let billingDate = item.billingDate;
    billingDate = entry?.bills[0]?.jsonCfdi
        ? entry?.bills[0]?.jsonCfdi?.Fecha
        : entry?.bills[0]?.foreignInvoice?.invoiceDate;

    let paymentDate = item.billingDate;
    paymentDate = entry?.bills[0]?.jsonCfdi
        ? entry?.bills[0]?.paymentDate
        : entry?.bills[0]?.paymentDate;

    return {billingRef, billingDate, paymentDate};
};

const extractDateInfo = (dateStr) => {
    const monthMap = {
        ENE: {fullName: 'Enero', monthNumber: 1},
        FEB: {fullName: 'Febrero', monthNumber: 2},
        MAR: {fullName: 'Marzo', monthNumber: 3},
        ABR: {fullName: 'Abril', monthNumber: 4},
        MAY: {fullName: 'Mayo', monthNumber: 5},
        JUN: {fullName: 'Junio', monthNumber: 6},
        JUL: {fullName: 'Julio', monthNumber: 7},
        AGO: {fullName: 'Agosto', monthNumber: 8},
        SEP: {fullName: 'Septiembre', monthNumber: 9},
        OCT: {fullName: 'Octubre', monthNumber: 10},
        NOV: {fullName: 'Noviembre', monthNumber: 11},
        DIC: {fullName: 'Diciembre', monthNumber: 12},
    };
    try {
        const regex = /FO(\d{2})_(\w{3})_/;
        const match = dateStr.match(regex);

        const monthAbbr = match[2];
        const yearSuffix = match[1];

        const currentYear = new Date().getFullYear();
        const baseYear = Math.floor(currentYear / 100) * 100;
        const year = baseYear + parseInt(yearSuffix, 10);

        const monthInfo = monthMap[monthAbbr];

        return {
            year,
            monthFullName: monthInfo.fullName,
            monthNumber: monthInfo.monthNumber,
        };
    } catch (e) {
        return {
            year: '-',
            monthFullName: '',
            monthNumber: '',
        };
    }
};

const createFormattedObject = (po, item, {entry = null} = {}) => {
    const {year, monthFullName} = extractDateInfo(item.productDetails[0].description);
    const data = {
        societyName: po.buyerBranchOffice.name,
        scheduledPaymentMonth: monthFullName,
        scheduledPaymentYear: year,
        orderId: po.referenceId,
        pos: item.position,
        frameworkContractAmount: '$' + po.contractAmount,
        contractBusinessNumber: po.contractBusinessNumber,
        billReference: getBillData(item, entry).billingRef,
        billingDate: item.billingDate,
        sku: item.productDetails[0].sku,
        postingDate: getBillData(item, entry).billingDate,
        billAmount: getNetImport(po.type, po.status, po.materialType, item, entry),
        currency: item.productDetails[0].currency,
        status: item.frontStatus,
        orderFindId: po.id,
        paymentDate: item.paymentDate || getBillData(item, entry).paymentDate,
        retentionPercentage: item.billingRetPercentage,
        message: item.details,
    };
    return data;
};

const formatOrdersModelorama = (orders) =>
    orders.flatMap((po) =>
        po.lineItems.flatMap((item) => {
            const lineItembillValidations = item.bill?.validations[0]?.validationData || [];
            const isBillValidated = false;

            const newStatus = item.frontStatus;

            return item.entries.length === 0
                ? [
                      createFormattedObject(po, item, {
                          lineItembillValidations,
                          isBillValidated,
                          newStatus,
                      }),
                  ]
                : item.entries.map((entry) =>
                      createFormattedObject(po, item, {
                          lineItembillValidations,
                          isBillValidated,
                          entry,
                          newStatus,
                      })
                  );
        })
    );

export {formatOrdersModelorama};
