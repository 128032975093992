import {
    keyTypeFile,
    NEX_STEP_UPLOAD_FILES_AGENTS,
    POST_UPLOAD_FILES_AGENTS,
    POST_UPLOAD_FILES_AGENTS_ERROR,
    POST_UPLOAD_FILES_AGENTS_SUCCESS,
    RESET_FORM_UPLOAD_FILES_AGENTS,
    SET_CURRENT_AGENT,
    CLEAN_ERROR_PROPERTY,
} from '@/components/orderSimply/redux/types/OrderAgentsTypes';
import axios from 'axios';

export const postUploadFilesAgents = (files, expenseAccount) => {
    return {
        type: POST_UPLOAD_FILES_AGENTS,
        value: {files, expenseAccount},
    };
};
export const postUploadFilesAgentsSuccess = (data) => {
    return {
        type: POST_UPLOAD_FILES_AGENTS_SUCCESS,
        value: data,
    };
};

export const postUploadFilesAgentsError = (error, result) => {
    return {
        type: POST_UPLOAD_FILES_AGENTS_ERROR,
        value: error,
    };
};
export const nextStepUploadFilesAgents = () => {
    return {
        type: NEX_STEP_UPLOAD_FILES_AGENTS,
    };
};
export const resetFormUploadFilesAgentsError = () => {
    return {
        type: RESET_FORM_UPLOAD_FILES_AGENTS,
    };
};

export const cleanErrorProperty = () => {
    return {
        type: CLEAN_ERROR_PROPERTY,
    };
};

export function fetchPostValidateFilesAgents(token, values, customRequest) {
    const headers = {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'multipart/form-data',
    };
    return async (dispatch) => {
        dispatch(postUploadFilesAgents(values.file, values.expenseAccount));
        const formData = new FormData();

        formData.append('expendAccount', values.expendAccount);

        Array.from(values.file).forEach((file) => {
            formData.append(keyTypeFile[file.type], file);
        });

        axios
            .post(
                `${process.env.REACT_APP_BASE_URL}/v2/customs-agents/upload-invoices/custom-request/${customRequest}`,
                formData,
                {
                    headers,
                }
            )
            .then(({data}) => {
                dispatch(postUploadFilesAgentsSuccess(data.data));
            })
            .catch((error) => {
                dispatch(
                    postUploadFilesAgentsError(
                        `${error.response.data.error}|${error.response.data.message}`
                    )
                );
            });
    };
}

export function fetchPostFilesAgents(token, values) {
    const headers = {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'multipart/form-data',
    };
    return async (dispatch) => {
        dispatch(postUploadFilesAgents(values.file, values.expenseAccount));
        const formData = new FormData();

        formData.append('expendAccount', values.expendAccount);

        Array.from(values.file).forEach((file) => {
            formData.append(keyTypeFile[file.type], file);
        });

        axios
            .post(`${process.env.REACT_APP_BASE_URL}/v2/customs-agents/upload-invoices`, formData, {
                headers,
            })
            .then(({data}) => {
                dispatch(postUploadFilesAgentsSuccess(data.data));
            })
            .catch((error) => {
                dispatch(postUploadFilesAgentsError(true));
            });
    };
}

export const setCurrentAgent = (item) => {
    return {
        type: SET_CURRENT_AGENT,
        value: item,
    };
};
