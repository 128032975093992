import {UserType} from '@/components/user/interface';
import {RootState} from '@/config/store';
import {Box, Button, Grid, TextField, Typography} from '@mui/material';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
const Profile: React.FC = () => {
    const {t} = useTranslation();
    const user = useSelector((state: RootState) => state.logins.user);

    return (
        <Box
            component="form"
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                p: 3,
            }}
            noValidate
            autoComplete="off"
        >
            <Typography variant="h6" gutterBottom>
                {t('account.perfil.title')}
            </Typography>
            <Grid
                container
                spacing={3}
                sx={{
                    maxWidth: '80%',
                    margin: 'auto',
                    backgroundColor: '#FFFFFF',
                    p: 3,
                    borderRadius: 1,
                    boxShadow: 3,
                }}
            >
                <Grid item xs={12} sm={6}>
                    <Grid container spacing={2} style={{marginLeft: '-40px'}}>
                        <Grid item xs={12}>
                            <TextField
                                label={t('account.perfil.name')}
                                defaultValue={user.profile?.firstLastName}
                                InputProps={{
                                    readOnly: true,
                                }}
                                variant="outlined"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label={t('account.perfil.last_name')}
                                defaultValue={user.profile?.secondLastName}
                                InputProps={{
                                    readOnly: true,
                                }}
                                variant="outlined"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label={t('account.perfil.email')}
                                defaultValue={user.email}
                                InputProps={{
                                    readOnly: true,
                                }}
                                variant="outlined"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label={t('account.perfil.role')}
                                defaultValue={user.rolesAssign?.join(',')}
                                InputProps={{
                                    readOnly: true,
                                }}
                                variant="outlined"
                                fullWidth
                            />
                        </Grid>
                        {UserType.INTERNAL_USER !== user.type && (
                            <Grid item xs={12}>
                                <TextField
                                    label={t('account.perfil.phone')}
                                    defaultValue={user.phone}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>
                        )}

                        {UserType.INTERNAL_USER !== user.type && (
                            <Grid item xs={12}>
                                <TextField
                                    label={t('account.perfil.curp')}
                                    defaultValue={
                                        user.company.taxProfiles?.length > 0
                                            ? user.company.taxProfiles[0].curp
                                            : ''
                                    }
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>
                        )}
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Grid container spacing={2}>
                        {UserType.INTERNAL_USER !== user.type && (
                            <Grid item xs={12}>
                                <TextField
                                    label={t('account.perfil.company')}
                                    defaultValue={user.company.name}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <TextField
                                label={t('account.perfil.enterprise')}
                                defaultValue={user.enterprise.name}
                                InputProps={{
                                    readOnly: true,
                                }}
                                variant="outlined"
                                fullWidth
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                label={t('account.perfil.country')}
                                defaultValue={user.country.name}
                                InputProps={{
                                    readOnly: true,
                                }}
                                variant="outlined"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label={t('account.perfil.city')}
                                defaultValue={
                                    user.company.taxProfiles?.length > 0
                                        ? user.company.taxProfiles[0].address?.city
                                        : ''
                                }
                                InputProps={{
                                    readOnly: true,
                                }}
                                variant="outlined"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label={t('account.perfil.zip_code')}
                                defaultValue={
                                    user.company.taxProfiles?.length > 0
                                        ? user.company.taxProfiles[0].address?.zipCode
                                        : ''
                                }
                                InputProps={{
                                    readOnly: true,
                                }}
                                variant="outlined"
                                fullWidth
                            />
                        </Grid>
                        {UserType.INTERNAL_USER !== user.type && (
                            <Grid item xs={12}>
                                <TextField
                                    label={t('account.perfil.rfc')}
                                    defaultValue={
                                        user.company.taxProfiles?.length > 0
                                            ? user.company.taxProfiles[0].taxId
                                            : ''
                                    }
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>
                        )}
                        {UserType.INTERNAL_USER !== user.type && (
                            <Grid item xs={12}>
                                <TextField
                                    label={t('account.perfil.tax_regime')}
                                    defaultValue={
                                        user.company.taxProfiles?.length > 0
                                            ? user.company.taxProfiles[0].taxRegimes?.join(',')
                                            : ''
                                    }
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>
                        )}
                    </Grid>
                </Grid>
                {UserType.INTERNAL_USER !== user.type && (
                    <Grid item xs={12}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                const url = 'https://mdweb.ab-inbev.com:9443/login';
                                window.open(url, '_blank');
                            }}
                            fullWidth
                        >
                            {t('account.perfil.edit')}
                        </Button>
                    </Grid>
                )}
            </Grid>
        </Box>
    );
};

export default Profile;
