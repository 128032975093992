import {styled} from '@mui/system';
import React from 'react';
import {useTranslation} from 'react-i18next';

export const statusTextValues = {
    CREATED: 'CREATED',
    REJECTED: 'REJECTED',
    CONFIRMED: 'CONFIRMED',
    DELIVERED: 'DELIVERED',
    BILL_VALIDATED: 'BILL_VALIDATED',
    INVOICE_IN_VALIDATION: 'INVOICE_IN_VALIDATION',
    INVOICE_IN_PROCESS: 'INVOICE_IN_PROCESS',
    REJECTED_INVOICE: 'REJECTED_INVOICE',
    SCHEDULED_TO_PAY: 'SCHEDULED_TO_PAY',
    PAID: 'PAID',
    CLOSED: 'CLOSED',
    BLOCKED: 'BLOCKED',
};

const StyledStatus = styled('div')(({color, bgColor}) => ({theme}) => ({
    color: color,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    backgroundColor: bgColor,
    fontSize: '9.5px',
    fontWeight: 600,
    fontFamily: theme?.typography?.fontFamily || 'Poppins',
    width: '90px',
    height: '30px',
    borderRadius: '4px',
}));

const StatusText = ({status, source = 'default'}) => {
    const {t} = useTranslation();
    const getStatusText = (status) => {
        return statusTextValues[status] || 'Desconocido';
    };

    const getColor = (status) => {
        const statusColors = {
            DELIVERED: '#00ACFF',
            CREATED: '#694D00',
            REJECTED: '#FF5722',
            PROCESS_PENDING: '#fff',
            BILL_VALIDATED: '#fff',
            CLOSED: 'rgba(146, 26, 40, 1)',
            INVOICE_IN_PROCESS: 'rgba(0, 172, 255, 1)',
            PARTIALLY_DELIVERED: '#262626',
            BLOCKED: 'rgba(146, 26, 40, 1)',
            REJECTED_INVOICE: '#260C05',
        };
        return statusColors[status] || '#4BBC68';
    };

    const color = getColor(status);

    const getColorFromHash = (status) => {
        const hashColors = {
            DELIVERED: 'rgba(0, 172, 255, 0.2)',
            CREATED: 'rgba(252, 248, 231, 0.82)',
            REJECTED: '#FFDDD3',
            BILL_VALIDATED: '#45B08C',
            CLOSED: 'rgba(244, 232, 233, 1)',
            INVOICE_IN_PROCESS: 'rgba(229, 247, 255, 1)',
            PARTIALLY_DELIVERED: '#FCF8E7',
            BLOCKED: '#FF7474',
            REJECTED_INVOICE: '#FF7474',
        };
        return hashColors[status] || '#DBF2E1';
    };

    const bgColor = getColorFromHash(status);
    const borderColor = getColor(status);
    const statusText =
        source === 'default'
            ? t(`modelorama.table.status.${getStatusText(status)}`)
            : t(`billingStatement.states.${getStatusText(status)}`);

    return (
        <StyledStatus color={color} bgColor={bgColor} borderColor={borderColor}>
            {statusText}
        </StyledStatus>
    );
};

export default StatusText;
