'use client';
import {FormValuesXmlValidate} from '@/components/orderSimply/interfaces/order-agents.interface';
import {
    fetchPostValidateFilesAgents,
    cleanErrorProperty,
} from '@/components/orderSimply/redux/actions/OrderAgentsActions';
import {ButtonCancel, Loader, SaveButton} from '@/components/orderSimply/Styled';
import {UploadDragAndDrop} from '@/components/UI/atoms/inputs/UploadDragAndDrop';
import {ALLOWED_FILE_TYPE_ZIP} from '@/components/UI/atoms/inputs/UploadDragAndDrop/UploadDragAndDrop';
import {AppDispatch, RootState} from '@/config/store';
import {yupResolver} from '@hookform/resolvers/yup';
import {Box} from '@mui/material';
import ErrorToast from '@components/auth/componenents/ErrorToast';
import React, {useEffect} from 'react';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import * as yup from 'yup';

const initialValues: FormValuesXmlValidate = {
    expenseAccount: '',
    file: '',
};
export type ValidateXmlInvoicesAgentProps = {
    closeModal: () => void;
};

const UploadFilesAgent: React.FC<ValidateXmlInvoicesAgentProps> = ({closeModal}) => {
    const dispatch: AppDispatch = useDispatch();
    const token = useSelector((state: RootState) => state.logins.accessToken);
    const loading = useSelector((state: RootState) => state.orderssimply.loading);
    const error = useSelector((state: RootState) => state.orderssimply.error);
    const currentAgent = useSelector((state: RootState) => state.orderssimply.currentAgent);
    const {t, i18n} = useTranslation();

    const schemaFormModal = yup.object({
        file: yup.mixed().required('Archivos requerido'),
    });

    const {
        handleSubmit,
        control,
        formState: {errors},

        watch,
    } = useForm<FormValuesXmlValidate>({
        resolver: yupResolver(schemaFormModal),
        defaultValues: initialValues,
        mode: 'onChange',
    });

    const watchForm = watch();
    const onSubmit = () => {
        dispatch(
            fetchPostValidateFilesAgents(
                token,
                {
                    ...watchForm,
                },
                currentAgent.request
            )
        );
    };

    const comString = (text1: string, text2: string) => {
        const index =
            i18n.language === 'en' ? text1.indexOf('Supplier') : text1.indexOf('asociado');
        const str1 = text1.slice(0, index + 8) + ': ' + text2 + ' ' + text1.slice(index + 8);
        return str1;
    };

    useEffect(() => {
        if (error) {
            const [err, msg] = error.split('|');
            ErrorToast({title: comString(t(`confirmposimply.errors.${err}`), msg)});
            dispatch(cleanErrorProperty());
        }
    }, [error]);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Box
                display="flex"
                p={1}
                mt={2}
                flexDirection={'column'}
                sx={{justifyContent: 'space-between'}}
            >
                <Box minWidth={'600px'}>
                    <UploadDragAndDrop
                        accept={ALLOWED_FILE_TYPE_ZIP}
                        errors={errors}
                        control={control}
                        name={'file'}
                        MaxFileSizeMb={20}
                        multiple={true}
                    />
                </Box>
            </Box>

            <Box
                sx={{
                    display: 'flex',
                    height: '56px',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                }}
            >
                <ButtonCancel onClick={closeModal}>{t('cancel').toUpperCase()}</ButtonCancel>
                <SaveButton
                    disabled={loading}
                    sx={{marginRight: '8px'}}
                    onClick={handleSubmit(onSubmit)}
                >
                    {loading ? <Loader size={24} /> : t('next').toUpperCase()}
                </SaveButton>
            </Box>
        </form>
    );
};

export default UploadFilesAgent;
