import {
    GET_EXPENSE_ACCOUNT,
    GET_EXPENSE_ACCOUNT_ERROR,
    GET_EXPENSE_ACCOUNT_SUCCESS,
    NEX_STEP_UPLOAD_FILES_AGENTS,
    POST_UPLOAD_FILES_AGENTS,
    POST_UPLOAD_FILES_AGENTS_ERROR,
    POST_UPLOAD_FILES_AGENTS_SUCCESS,
    RESET_FORM_UPLOAD_FILES_AGENTS,
    SET_CURRENT_AGENT,
    CLEAN_ERROR_PROPERTY,
} from '@/components/orderSimply/redux/types/OrderAgentsTypes';
import {
    ACCEPTED_AND_REJECTED_TERMS_CACHE,
    CHECK_TERMS_AND_DOWNLOAD,
    CLEAN_ACCEPTED_AND_REJECTED_TERMS_CACHE,
    CREATE_FILE_XML_3WM,
    CREATE_FILE_XML_3WM_ERROR,
    CREATE_FILE_XML_3WM_SUCCESS,
    CREATE_FILE_XML_NOTE,
    CREATE_FILE_XML_NOTE_ERROR,
    CREATE_FILE_XML_NOTE_SUCCESS,
    GET_DOWNLOAD_ORDERSSIMPLY,
    GET_DOWNLOAD_ORDERSSIMPLY_ERROR,
    GET_DOWNLOAD_ORDERSSIMPLY_SUCCESS,
    GET_MASSIVE_JOB,
    GET_MASSIVE_JOB_ERROR,
    GET_MASSIVE_JOB_ERROR_DOMAIN,
    GET_MASSIVE_JOB_SUCCESS,
    GET_ORDERSSIMPLY,
    GET_ORDERSSIMPLY_CONFIRM,
    GET_ORDERSSIMPLY_CONFIRM_ERROR,
    GET_ORDERSSIMPLY_CONFIRM_SIMPLY,
    GET_ORDERSSIMPLY_CONFIRM_SIMPLY_ERROR,
    GET_ORDERSSIMPLY_CONFIRM_SIMPLY_SUCCESS,
    GET_ORDERSSIMPLY_CONFIRM_SUCCESS,
    GET_ORDERSSIMPLY_ERROR,
    GET_ORDERSSIMPLY_SUCCESS,
    GET_ORDERS_AGENTS,
    GET_ORDERS_AGENTS_ERROR,
    GET_ORDERS_AGENTS_SUCCESS,
    GET_ORDERS_ERRORS,
    GET_ORDERS_ERRORS_ERROR,
    GET_ORDERS_ERRORS_SUCCESS,
    GET_ORDERS_FILTERS,
    GET_ORDER_ENTRY_SHEET,
    GET_ORDER_ENTRY_SHEET_ERROR,
    GET_ORDER_ENTRY_SHEET_SUCCESS,
    HIDE_TERMS_MODAL,
    POST_PARSE_XML,
    POST_PARSE_XML_ERROR,
    POST_PARSE_XML_SUCCESS,
    POST_XML_3WM,
    POST_XML_3WM_ERROR,
    POST_XML_3WM_SUCCESS,
    POST_ZIP_MASSIVE,
    POST_ZIP_MASSIVE_ERROR,
    POST_ZIP_MASSIVE_ERROR_DOMAIN,
    POST_ZIP_MASSIVE_SUCCESS,
    SEND_ACCEPT_TERMS,
    SEND_ACCEPT_TERMS_ERROR,
    SEND_ACCEPT_TERMS_SUCCESS,
    SET_MASSIVE_SUCCESS,
    SHOW_TERMS_MODAL,
} from '@/components/orderSimply/redux/types/OrdersSimplyTypes';
import {ErrorType} from '@/components/orderSimply/utils/format-orders';

const initialState = {
    orderssimply: [],
    entrysheets: [],
    count: 0,
    page: 1,
    limit: 10,
    totalPages: 1,
    detailsorderssimply: [],
    detailscount: 0,
    detailspage: 1,
    detailslimit: 10,
    detailstotalPages: 1,
    loading: false,
    error: false,
    errors: [],
    message: '',
    types: '',
    name: '',
    firstRegistrationDate: null,
    latestRegistrationDate: null,
    filters: {},
    filesOrderAgents: [],
    expendAccount: '',
    stepOrderAgents: 0,
    xmlsOrderAgents: [],
    domainErrors: [],
    loadMassiveSucces: false,
    acceptedAndRejectedTermsCache: [],
    expenseAccountList: [],
    countExpenseAccount: 0,
    pageExpenseAccount: 1,
    limitExpenseAccount: 10,
    totalPagesExpenseAccount: 1,
    loadingExpenseAccount: false,
    errorExpenseAccount: false,
    errorsExpenseAccount: [],
    messageExpenseAccount: '',
    currentAgent: {},

    jsonCfdi: {},
    billErrors: [],

    termsModal: {
        showTermsModal: false,
        selectedItem: null,
    },
};

const orderssimply = (state = initialState, action) => {
    switch (action.type) {
        case GET_ORDERSSIMPLY:
            return {
                ...state,
                loading: true,
            };
        case GET_ORDERSSIMPLY_SUCCESS:
            return {
                ...state,
                orderssimply: action.value,
                count: action.metadata.metadata.count,
                page: action.metadata.metadata.currentPage,
                limit: action.metadata.metadata.limit,
                totalPages: action.metadata.metadata.totalPages,
                firstRegistrationDate: action.metadata.metadata.firstRegistrationDate,
                latestRegistrationDate: action.metadata.metadata.latestRegistrationDate,
                loading: false,
            };
        case GET_ORDERSSIMPLY_ERROR:
            return {
                ...state,
                error: action.value,
                loading: false,
            };
        case GET_DOWNLOAD_ORDERSSIMPLY:
            return {
                ...state,
                loading: true,
            };
        case GET_DOWNLOAD_ORDERSSIMPLY_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case GET_DOWNLOAD_ORDERSSIMPLY_ERROR:
            return {
                ...state,
                error: action.value,
                loading: false,
            };
        case GET_ORDERSSIMPLY_CONFIRM:
            return {
                ...state,
                loading: true,
            };
        case GET_ORDERSSIMPLY_CONFIRM_SUCCESS:
            return {
                ...state,
                orderssimply: action.value,
                count: action.metadata.metadata.count,
                page: action.metadata.metadata.currentPage,
                limit: action.metadata.metadata.limit,
                totalPages: action.metadata.metadata.totalPages,
                firstRegistrationDate: action.metadata.metadata.firstRegistrationDate,
                latestRegistrationDate: action.metadata.metadata.latestRegistrationDate,
                loading: false,
            };
        case GET_ORDERSSIMPLY_CONFIRM_ERROR:
            return {
                ...state,
                error: action.value,
                loading: false,
            };
        case GET_ORDERSSIMPLY_CONFIRM_SIMPLY:
            return {
                ...state,
                loading: true,
            };
        case GET_ORDERSSIMPLY_CONFIRM_SIMPLY_SUCCESS:
            return {
                ...state,
                orderssimply: action.value,
                count: action.metadata.metadata.count,
                page: action.metadata.metadata.currentPage,
                limit: action.metadata.metadata.limit,
                totalPages: action.metadata.metadata.totalPages,
                firstRegistrationDate: action.metadata.metadata.firstRegistrationDate,
                latestRegistrationDate: action.metadata.metadata.latestRegistrationDate,
                loading: false,
                error: false,
            };
        case GET_ORDERSSIMPLY_CONFIRM_SIMPLY_ERROR:
            return {
                ...state,
                error: action.value,
                loading: false,
            };
        case GET_ORDERS_ERRORS:
            return {
                ...state,
                loading: true,
            };
        case GET_ORDERS_ERRORS_SUCCESS:
            return {
                ...state,
                billErrors: action.value,
                loading: false,
            };
        case GET_ORDERS_ERRORS_ERROR:
            return {
                ...state,
                error: action.value,
                loading: false,
            };
        case GET_ORDERS_FILTERS:
            return {
                ...state,
                filters: action.value,
                loading: false,
            };
        case GET_ORDERS_AGENTS:
            return {
                ...state,
                orderssimply: action.value,
                loading: true,
            };
        case GET_ORDERS_AGENTS_SUCCESS:
            return {
                ...state,
                orderssimply: action.value.data,
                count: action.metadata.metadata.count,
                page: action.metadata.metadata.currentPage,
                limit: action.metadata.metadata.limit,
                totalPages: action.metadata.metadata.totalPages,
                firstRegistrationDate: action.metadata.metadata.firstRegistrationDate,
                latestRegistrationDate: action.metadata.metadata.latestRegistrationDate,
                loading: false,
            };
        case GET_EXPENSE_ACCOUNT:
            return {
                ...state,
                loadingExpenseAccount: true,
            };
        case GET_EXPENSE_ACCOUNT_SUCCESS:
            return {
                ...state,
                expenseAccountList: action.value.data,
                countExpenseAccount: action.metadata?.metadata?.count ?? 0,
                pageExpenseAccount: action.metadata?.metadata?.currentPage ?? 1,
                limitExpenseAccount: action.metadata?.metadata?.limit ?? 10,
                totalPagesExpenseAccount: action.metadata?.metadata?.totalPages ?? 1,
                loadingExpenseAccount: false,
            };
        case GET_EXPENSE_ACCOUNT_ERROR:
            return {
                ...state,
                errorExpencAccount: action.value,
                loadingExpencAccount: false,
            };
        case GET_ORDERS_AGENTS_ERROR:
            return {
                ...state,
                error: action.value,
                loading: false,
            };
        case CREATE_FILE_XML_3WM:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case CREATE_FILE_XML_3WM_SUCCESS:
            return {
                ...state,
                ...action.value,
                loading: false,
            };
        case CREATE_FILE_XML_3WM_ERROR:
            return {
                ...state,
                ...action.value,
                error: ErrorType.RULE,
                errors: action.value,
                loading: false,
            };
        case POST_PARSE_XML:
            return {
                ...state,
                loading: true,
            };
        case POST_PARSE_XML_SUCCESS:
            return {
                ...state,
                jsonCfdi: action.value,
                loading: false,
            };
        case POST_PARSE_XML_ERROR:
            return {
                ...state,
                error: action.value,
                loading: false,
            };
        case POST_XML_3WM:
            return {
                ...state,
                loading: true,
            };
        case POST_XML_3WM_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case POST_XML_3WM_ERROR:
            return {
                ...state,
                error: action.value,
                loading: false,
            };
        case GET_ORDER_ENTRY_SHEET_SUCCESS:
            return {
                ...state,
                entrysheets: action.value.data,
                loading: false,
            };
        case GET_ORDER_ENTRY_SHEET:
            return {
                ...state,
                loading: true,
            };
        case GET_ORDER_ENTRY_SHEET_ERROR:
            return {
                ...state,
                error: action.value,
                loading: false,
            };
        case CREATE_FILE_XML_NOTE:
            return {
                ...state,
                loading: true,
            };
        case CREATE_FILE_XML_NOTE_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case CREATE_FILE_XML_NOTE_ERROR:
            return {
                ...state,
                errors: action.value.errors,
                loading: false,
            };
        case POST_UPLOAD_FILES_AGENTS:
            return {
                ...state,
                filesOrderAgents: action.value.files,
                expendAccount: action.value.expendAccount,
                loading: true,
            };
        case POST_UPLOAD_FILES_AGENTS_SUCCESS: {
            const hasErrrors = action.value.some(({errors}) => {
                return errors && Object.keys(errors).length > 0;
            });
            const step = hasErrrors ? state.stepOrderAgents + 1 : state.stepOrderAgents + 2;
            return {
                ...state,
                xmlsOrderAgents: action.value.map((item) => ({
                    ...item,
                })),
                stepOrderAgents: step,
                loading: false,
            };
        }
        case POST_UPLOAD_FILES_AGENTS_ERROR:
            return {
                ...state,
                error: action.value,
                loading: false,
            };
        case RESET_FORM_UPLOAD_FILES_AGENTS:
            return {
                ...state,
                filesOrderAgents: [],
                expendAccount: '',
                stepOrderAgents: 0,
                xmlsOrderAgents: [],
            };
        case NEX_STEP_UPLOAD_FILES_AGENTS:
            return {
                ...state,

                stepOrderAgents: +state.stepOrderAgents + 1,
            };
        case POST_ZIP_MASSIVE:
            return {
                ...state,
                loadMassiveSucces: false,
                domainErrors: [],
                errors: [],
                loading: true,
            };
        case POST_ZIP_MASSIVE_SUCCESS:
            return {
                ...state,
                loadMassiveSucces: true,
                domainErrors: [],
                errors: [],
                loading: false,
            };
        case POST_ZIP_MASSIVE_ERROR:
            return {
                ...state,
                errors: action.value.errors,
                loadMassiveSucces: false,
                loading: false,
            };
        case POST_ZIP_MASSIVE_ERROR_DOMAIN:
            return {
                ...state,
                domainErrors: action.value.errors,
                loadMassiveSucces: false,
                loading: false,
            };
        case GET_MASSIVE_JOB:
            return {
                ...state,
                loadMassiveSucces: false,
                domainErrors: [],
                errors: [],
                loading: true,
            };
        case GET_MASSIVE_JOB_SUCCESS:
            return {
                ...state,
                domainErrors: [],
                errors: action.value.errors,
                loading: false,
                loadMassiveSucces: true,
            };
        case GET_MASSIVE_JOB_ERROR:
            return {
                ...state,
                domainErrors: [],
                errors: action.value.errors,
                loading: false,
                loadMassiveSucces: false,
            };
        case GET_MASSIVE_JOB_ERROR_DOMAIN:
            return {
                ...state,
                domainErrors: action.value.errors,
                loadMassiveSucces: false,
                loading: false,
            };
        case SET_MASSIVE_SUCCESS: {
            return {
                ...state,
                loadMassiveSucces: action.value,
                domainErrors: [],
                errors: [],
                loading: false,
            };
        }
        case SET_CURRENT_AGENT: {
            return {
                ...state,
                currentAgent: action.value,
            };
        }

        case CHECK_TERMS_AND_DOWNLOAD: {
            return state;
        }
        case SHOW_TERMS_MODAL: {
            return {
                ...state,
                termsModal: {showTermsModal: true, selectedItem: action.payload},
            };
        }
        case HIDE_TERMS_MODAL: {
            return {...state, termsModal: {showTermsModal: false, selectedItem: null}};
        }
        case SEND_ACCEPT_TERMS:
            return {
                ...state,
                loading: true,
            };
        case SEND_ACCEPT_TERMS_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case SEND_ACCEPT_TERMS_ERROR:
            return {
                ...state,
                error: action.value,
                loading: false,
            };
        case ACCEPTED_AND_REJECTED_TERMS_CACHE:
            return {
                ...state,
                acceptedAndRejectedTermsCache: action.payload,
            };

        case CLEAN_ACCEPTED_AND_REJECTED_TERMS_CACHE:
            return {
                ...state,
                acceptedAndRejectedTermsCache: [],
            };
        case CLEAN_ERROR_PROPERTY:
            return {
                ...state,
                error: false,
            };
        default:
            return state;
    }
};

export default orderssimply;
