import {statusTextValues} from '@/components/UI/atoms/buttons/constant/status-text';
import StatusText from '@/components/UI/atoms/buttons/StatusText';
import CustomPagination from '@/components/UI/organisms/pagination/Pagination';
import {AppDispatch, RootState} from '@/config/store';
import {Can} from '@/context/AbilityContext';
import {AccountStatusEnum, FeatureCodeEnum} from '@/enums/permissions.enum';
import {formatMoney} from '@/utils/utils';
import {
    TableCellChildrenSmall,
    TypographyStyled400,
} from '@components/billingStatement//styles/styles';
import BillingStatementDataLoader from '@components/billingStatement/components/billingStatementDataLoader';
import {IBillingStatementDetails, IConProp} from '@components/billingStatement/interface';
import {
    fetchBillingStatement,
    fetchSocietyDetails,
} from '@components/billingStatement/redux/reducers/actions/SocietyActions';
import IconDownload from '@components/manuals/images/IconDownload.svg';
import {TableCellHead, TableContainer} from '@components/UI/molecules/tables/TablePrincipal';
import {Box, Grid, IconButton, Menu, MenuItem, Tooltip} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

import {DateTime} from 'luxon';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
interface IDetailsTableProps {
    societyId: string;
    supplierId: string;
}
export const DetailsTable: React.FC<IDetailsTableProps> = ({societyId, supplierId}) => {
    const {t} = useTranslation();

    const dispatch: AppDispatch = useDispatch();
    const token = useSelector((state: RootState) => state.logins.accessToken);
    const loadingData = useSelector((state: RootState) => state.billingStatement.detailsLoading);
    const currentPage = useSelector((state: RootState) => state.billingStatement.detailsPage);
    const limit = useSelector((state: RootState) => state.billingStatement.detailsLimit);
    const societyDetails = useSelector((state: RootState) => state.billingStatement.detailsData);
    const detailsTotalPages = useSelector(
        (state: RootState) => state.billingStatement.detailsTotalPages
    );
    const filters = useSelector((state: RootState) => state.billingStatement.filters);
    const society = useSelector((state: RootState) => state.billingStatement.society);
    const supplier = useSelector((state: RootState) => state.billingStatement.supplier);
    const loadingFile = useSelector((state: RootState) => state.billingStatement.loadingFile);

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClickMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (!loadingFile) {
            setAnchorEl(event.currentTarget);
        }
    };

    const colProps: IConProp[] = [
        {id: 1, size: 1, align: 'center', name: t('consultpo.table.headers.pay_day')},
        {id: 2, size: 1, align: 'center', name: t('concept')},
        {
            id: 3,
            size: 1,
            align: 'center',
            name: t('confirmposimply.table.headers.invoice_reference'),
        },
        {id: 4, size: 2.5, align: 'center', name: t('text')},
        {id: 5, size: 1, align: 'center', name: t('billingStatement.posting_date')},
        {id: 6, size: 2, align: 'right', name: t('billingStatement.amount_local_currency')},
        {id: 7, size: 2.5, align: 'center', name: t('billingStatement.amount_document_currency')},
        {id: 8, size: 1, align: 'center', name: t('components.status_selector.title')},
    ];

    const handleCloseMenu = (option: string): void => {
        if (typeof option === 'string') {
            dispatch(
                fetchBillingStatement({
                    token,
                    format: option,
                    filters: {
                        ...filters,
                        supplierId: supplier,
                        society: society,

                        endDate: filters.dateEnd,
                        initDate: filters.dateInit,
                    },
                })
            );
        }
        setAnchorEl(null);
    };

    const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
        if (!loadingData && societyDetails && societyDetails.length > 0)
            dispatch(
                fetchSocietyDetails({
                    token,
                    page: value,
                    limit,
                    filters: {
                        ...filters,
                        supplierId: supplier,
                        society: society,
                        endDate: DateTime.fromJSDate(new Date(filters.dateEnd)).toFormat(
                            'yyyy-MM-dd'
                        ),
                        initDate: DateTime.fromJSDate(new Date(filters.dateInit)).toFormat(
                            'yyyy-MM-dd'
                        ),
                    },
                })
            );
    };

    const handleChangeRowsPerPage = (event: any) => {
        dispatch(
            fetchSocietyDetails({
                token,
                page: 1,
                limit: event.target.value,
                filters: {
                    ...filters,
                    supplierId: supplier,
                    society: society,
                    endDate: DateTime.fromJSDate(new Date(filters.dateEnd)).toFormat('MM-dd-yyyy'),
                    initDate: DateTime.fromJSDate(new Date(filters.dateInit)).toFormat(
                        'MM-dd-yyyy'
                    ),
                },
            })
        );
    };

    const getHeadcellStile = (key: number) => {
        const stile = {
            borderLeft: '',
            borderRight: '',
        };

        const last = colProps.slice(-1)[0];
        if (key === colProps[0].id) stile.borderLeft = '1px solid #C2D1D9';
        else if (last && key === last.id) stile.borderRight = '1px solid #C2D1D9';
        return stile;
    };

    const formatAmount = (item: IBillingStatementDetails, type: string) => {
        let amount = 0;
        let currency = '';
        if (type === 'local') {
            amount = item.localAmount;
            currency = item.localCurrency;
        } else if (type === 'doc') {
            amount = item.documentAmount;
            currency = item.documentCurrency;
        }
        const sign = item.type === 'Factura' ? '-' : '';
        return `${sign}${formatMoney(amount, currency)}`;
    };

    return (
        <>
            <Grid container>
                <Grid item xs={11}>
                    <TableContainer container sx={{width: '100%'}}>
                        {colProps.map((col) => (
                            <Grid
                                key={col.id}
                                item
                                xs={col.size}
                                display="grid"
                                sx={{
                                    ...getHeadcellStile(col.id),
                                    borderRight: 'none',
                                }}
                            >
                                <TableCellHead
                                    sx={{
                                        textAlign: col.align,
                                        paddingLeft: '10px',
                                        paddingRight: '10px',
                                    }}
                                >
                                    <Box sx={{width: '100%'}}>{col.name}</Box>
                                </TableCellHead>
                            </Grid>
                        ))}

                        <Grid item xs={12} display="grid">
                            <TableCellChildrenSmall
                                sx={{
                                    borderLeft: '1px solid #C2D1D9',

                                    minHeight: '19px',
                                }}
                            >
                                <Box sx={{width: '100%'}} />
                            </TableCellChildrenSmall>
                        </Grid>

                        {loadingData ? (
                            <BillingStatementDataLoader colProps={colProps} />
                        ) : societyDetails ? (
                            societyDetails
                                .filter((item: IBillingStatementDetails) => item !== null)
                                .map((item: IBillingStatementDetails, key: number) => {
                                    if (item.state === statusTextValues.CREATED)
                                        item.state = statusTextValues.INVOICE_IN_PROCESS;
                                    return (
                                        <Grid container key={key}>
                                            {/* Fecha de Pago */}
                                            <Grid item xs={colProps[0].size} display="grid">
                                                <TableCellChildrenSmall
                                                    sx={{borderLeft: '1px solid #C2D1D9'}}
                                                >
                                                    <Box sx={{width: '100%'}}>
                                                        <TypographyStyled400
                                                            sx={{textAlign: 'center'}}
                                                        >
                                                            {item?.paymentDate &&
                                                            item.paymentDate !== undefined
                                                                ? DateTime.fromJSDate(
                                                                      new Date(item.paymentDate)
                                                                  ).toFormat('dd / LL / y')
                                                                : ''}
                                                        </TypographyStyled400>
                                                    </Box>
                                                </TableCellChildrenSmall>
                                            </Grid>

                                            {/* Consepto */}
                                            <Grid item xs={colProps[1].size} display="grid">
                                                <TableCellChildrenSmall>
                                                    <Box sx={{width: '100%'}}>
                                                        <TypographyStyled400
                                                            sx={{textAlign: 'center'}}
                                                        >
                                                            {item?.type
                                                                ? t(
                                                                      'billingStatement.type_document.' +
                                                                          item.type
                                                                  )
                                                                : ''}
                                                        </TypographyStyled400>
                                                    </Box>
                                                </TableCellChildrenSmall>
                                            </Grid>

                                            {/* Referencia Factura */}
                                            <Grid item xs={colProps[2].size} display="grid">
                                                <TableCellChildrenSmall>
                                                    <Box sx={{width: '100%'}}>
                                                        <TypographyStyled400
                                                            sx={{textAlign: 'center'}}
                                                        >
                                                            {item.invoiceReference &&
                                                            item.invoiceReference !== 'undefined'
                                                                ? item.invoiceReference
                                                                : ''}
                                                        </TypographyStyled400>
                                                    </Box>
                                                </TableCellChildrenSmall>
                                            </Grid>

                                            {/* Texto */}
                                            <Grid item xs={colProps[3].size} display="grid">
                                                <TableCellChildrenSmall>
                                                    <Box sx={{width: '100%'}}>
                                                        <TypographyStyled400
                                                            sx={{textAlign: 'center'}}
                                                        >
                                                            {item.text}
                                                        </TypographyStyled400>
                                                    </Box>
                                                </TableCellChildrenSmall>
                                            </Grid>

                                            {/* Fecha documento */}
                                            <Grid item xs={colProps[4].size} display="grid">
                                                <TableCellChildrenSmall>
                                                    <Box sx={{width: '100%'}}>
                                                        <TypographyStyled400
                                                            sx={{textAlign: 'center'}}
                                                        >
                                                            {DateTime.fromJSDate(
                                                                new Date(item.documentDate)
                                                            ).toFormat('dd / LL / y')}
                                                        </TypographyStyled400>
                                                    </Box>
                                                </TableCellChildrenSmall>
                                            </Grid>

                                            {/* Monto (Moneda local) */}
                                            <Grid item xs={colProps[5].size} display="grid">
                                                <TableCellChildrenSmall>
                                                    <Box sx={{width: '100%', paddingRight: '10px'}}>
                                                        <TypographyStyled400
                                                            sx={{textAlign: 'right'}}
                                                        >
                                                            {(item.type === 'E' ? '-' : '') +
                                                                formatAmount(item, 'local')}
                                                        </TypographyStyled400>
                                                    </Box>
                                                </TableCellChildrenSmall>
                                            </Grid>

                                            {/* Monto (Moneda documento) */}
                                            <Grid item xs={colProps[6].size} display="grid">
                                                <TableCellChildrenSmall>
                                                    <Box sx={{width: '100%', paddingRight: '10px'}}>
                                                        <TypographyStyled400
                                                            sx={{textAlign: 'center'}}
                                                        >
                                                            {(item.type === 'E' ? '-' : '') +
                                                                formatAmount(item, 'doc')}
                                                        </TypographyStyled400>
                                                    </Box>
                                                </TableCellChildrenSmall>
                                            </Grid>

                                            {/* Estado */}
                                            <Grid item xs={colProps[7].size} display="grid">
                                                <TableCellChildrenSmall>
                                                    <Box sx={{width: '100%', margin: '5px'}}>
                                                        <StatusText
                                                            status={item.state}
                                                            source="statement_of_account"
                                                        />
                                                    </Box>
                                                </TableCellChildrenSmall>
                                            </Grid>
                                        </Grid>
                                    );
                                })
                        ) : null}
                        <Grid item xs={12} display="grid">
                            <TableCellChildrenSmall
                                sx={{
                                    borderLeft: '1px solid #C2D1D9',
                                    borderBottom: '1px solid #C2D1D9',
                                    minHeight: '19px',
                                }}
                            >
                                <Box sx={{width: '100%'}} />
                            </TableCellChildrenSmall>
                        </Grid>
                    </TableContainer>
                </Grid>
                {/* Acciones */}
                <Grid item xs={1} display="grid">
                    <TableContainer
                        container
                        sx={{
                            width: '100%',
                            borderBottom: '1px solid #C2D1D9',
                        }}
                    >
                        <Grid item xs={12} display="grid" sx={{borderRight: '1px solid #C2D1D9'}}>
                            <TableCellHead
                                sx={{
                                    textAlign: 'center',
                                    paddingLeft: '10px',
                                    paddingRight: '10px',
                                }}
                            >
                                <Box sx={{width: '100%'}}>{t('actions')}</Box>
                            </TableCellHead>
                        </Grid>
                        <Box
                            sx={{
                                display: 'flex',
                                width: '100%',
                                textAlign: 'center',
                                justifyContent: 'center',
                                alignItems: 'center',
                                borderRight: '1px solid #C2D1D9',
                            }}
                        >
                            <Tooltip title={t('billingStatement.download_document')}>
                                <IconButton aria-label="Descargar" onClick={handleClickMenu}>
                                    {loadingFile ? (
                                        <CircularProgress size={30} />
                                    ) : (
                                        <Can
                                            I={AccountStatusEnum.DOWNLOAD}
                                            a={FeatureCodeEnum.ACCOUNT_STATUS}
                                        >
                                            {societyDetails &&
                                                societyDetails.filter(
                                                    (item: IBillingStatementDetails) =>
                                                        item !== null
                                                ).length > 0 && (
                                                    <img
                                                        src={IconDownload}
                                                        style={{
                                                            cursor: 'pointer',
                                                        }}
                                                        title={t('download')}
                                                    />
                                                )}
                                        </Can>
                                    )}
                                </IconButton>
                            </Tooltip>
                            <Menu
                                id="basic-menu"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleCloseMenu}
                                MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                }}
                            >
                                <MenuItem
                                    onClick={() => {
                                        if (
                                            !loadingData &&
                                            societyDetails &&
                                            societyDetails.length > 0
                                        )
                                            handleCloseMenu('csv');
                                    }}
                                >
                                    <TypographyStyled400 sx={{textAlign: 'center'}}>
                                        {t('components.download_csv_btm.title')}
                                    </TypographyStyled400>
                                </MenuItem>
                                <MenuItem
                                    onClick={() => {
                                        if (
                                            !loadingData &&
                                            societyDetails &&
                                            societyDetails.length > 0
                                        )
                                            handleCloseMenu('xlsx');
                                    }}
                                >
                                    <TypographyStyled400 sx={{textAlign: 'center'}}>
                                        {t('components.download_xlsx_btm.title')}
                                    </TypographyStyled400>
                                </MenuItem>
                            </Menu>
                        </Box>
                    </TableContainer>
                </Grid>
            </Grid>
            <CustomPagination
                totalPages={detailsTotalPages}
                currentPage={currentPage}
                handleChange={handleChangePage}
                limit={limit}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
        </>
    );
};
