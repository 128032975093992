export const GET_ORDERSSIMPLY = 'GET_ORDERSSIMPLY';
export const GET_ORDERSSIMPLY_SUCCESS = 'GET_ORDERSSIMPLY_SUCCESS';
export const GET_ORDERSSIMPLY_ERROR = 'GET_ORDERSSIMPLY_ERROR';
export const GET_DOWNLOAD_ORDERSSIMPLY = 'GET_DOWNLOAD_ORDERSSIMPLY';
export const GET_DOWNLOAD_ORDERSSIMPLY_SUCCESS = 'GET_DOWNLOAD_ORDERSSIMPLY_SUCCESS';
export const GET_DOWNLOAD_ORDERSSIMPLY_ERROR = 'GET_DOWNLOAD_ORDERSSIMPLY_ERROR';
export const GET_ORDERSSIMPLY_CONFIRM = 'GET_ORDERSSIMPLY_CONFIRM';
export const GET_ORDERSSIMPLY_CONFIRM_SUCCESS = 'GET_ORDERSSIMPLY_CONFIRM_SUCCESS';
export const GET_ORDERSSIMPLY_CONFIRM_ERROR = 'GET_ORDERSSIMPLY_CONFIRM_ERROR';
export const GET_ORDERSSIMPLY_CONFIRM_SIMPLY = 'GET_ORDERSSIMPLY_CONFIRM_SIMPLY';
export const GET_ORDERSSIMPLY_CONFIRM_SIMPLY_SUCCESS = 'GET_ORDERSSIMPLY_CONFIRM_SIMPLY_SUCCESS';
export const GET_ORDERSSIMPLY_CONFIRM_SIMPLY_ERROR = 'GET_ORDERSSIMPLY_CONFIRM_SIMPLY_ERROR';
export const GET_ORDERS_ERRORS = 'GET_ORDERS_ERRORS';
export const GET_ORDERS_ERRORS_SUCCESS = 'GET_ORDERS_ERRORS_SUCCESS';
export const GET_ORDERS_ERRORS_ERROR = 'GET_ORDERS_ERRORS_ERROR';
export const GET_ORDERS_FILTERS = 'GET_ORDERS_FILTERS';
export const GET_ORDERS_AGENTS = 'GET_ORDERS_AGENTS';
export const GET_ORDERS_AGENTS_SUCCESS = 'GET_ORDERS_AGENTS_SUCCESS';

export const GET_ORDERS_AGENTS_ERROR = 'GET_ORDERS_AGENTS_ERROR';
export const GET_DOWNLOAD_ORDERSAGENTS = 'GET_DOWNLOAD_ORDERSAGENTS';
export const GET_DOWNLOAD_ORDERSAGENTS_SUCCESS = 'GET_DOWNLOAD_ORDERSAGENTS_SUCCESS';
export const GET_DOWNLOAD_ORDERSAGENTS_ERROR = 'GET_DOWNLOAD_ORDERSAGENTS_ERROR';
export const GET_DOWNLOAD_ORDERSMODELO = 'GET_DOWNLOAD_ORDERSMODELO';
export const GET_DOWNLOAD_ORDERSMODELO_SUCCESS = 'GET_DOWNLOAD_ORDERSMODELO_SUCCESS';
export const GET_DOWNLOAD_ORDERSMODELO_ERROR = 'GET_DOWNLOAD_ORDERSMODELO_ERROR';
export const GET_DOWNLOAD_DATA_ORDER = 'GET_DOWNLOAD_DATA_ORDER';
export const GET_DOWNLOAD_DATA_ORDER_SUCCESS = 'GET_DOWNLOAD_DATA_ORDER_SUCCESS';
export const GET_DOWNLOAD_DATA_ORDER_ERROR = 'GET_DOWNLOAD_DATA_ORDER_ERROR';

export const CREATE_FILE_XML_3WM = 'CREATE_FILE_XML_3WM';
export const CREATE_FILE_XML_3WM_SUCCESS = 'CREATE_FILE_XML_3WM_SUCCESS';
export const CREATE_FILE_XML_3WM_ERROR = 'CREATE_FILE_XML_3WM_ERROR';
export const POST_XML_3WM = 'POST_XML_3WM';
export const POST_XML_3WM_SUCCESS = 'POST_XML_3WM_SUCCESS';
export const POST_XML_3WM_ERROR = 'POST_XML_3WM_ERROR';
export const POST_PARSE_XML = 'POST_PARSE_XML';
export const POST_PARSE_XML_SUCCESS = 'POST_PARSE_XML_SUCCESS';
export const POST_PARSE_XML_ERROR = 'POST_PARSE_XML_ERROR';

export const GET_ORDER_ENTRY_SHEET = 'GET_ORDER_ENTRY_SHEET';
export const GET_ORDER_ENTRY_SHEET_SUCCESS = 'GET_ORDER_ENTRY_SHEET_SUCCESS';
export const GET_ORDER_ENTRY_SHEET_ERROR = 'GET_ORDER_ENTRY_SHEET_ERROR';

export const CREATE_FILE_XML_NOTE = 'CREATE_FILE_XML_NOTE';
export const CREATE_FILE_XML_NOTE_SUCCESS = 'CREATE_FILE_XML_NOTE_SUCCESS';
export const CREATE_FILE_XML_NOTE_ERROR = 'CREATE_FILE_XML_NOTE_ERROR';

export const POST_ZIP_MASSIVE = 'POST_ZIP_MASSIVE';
export const POST_ZIP_MASSIVE_SUCCESS = 'POST_ZIP_MASSIVE_SUCCESS';
export const POST_ZIP_MASSIVE_ERROR = 'POST_ZIP_MASSIVE_ERROR';
export const POST_ZIP_MASSIVE_ERROR_DOMAIN = 'POST_ZIP_MASSIVE_ERROR_DOMAIN';
export const SET_MASSIVE_SUCCESS = 'SET_MASSIVE_SUCCESS';

export const CHECK_TERMS_AND_DOWNLOAD = 'CHECK_TERMS_AND_DOWNLOAD';
export const VERIFY_TERMS_AND_COMPLETE_ACTION = 'VERIFY_TERMS_AND_COMPLETE_ACTION';
export const HIDE_TERMS_MODAL = 'HIDE_TERMS_MODAL';
export const SHOW_TERMS_MODAL = 'SHOW_TERMS_MODAL';
export const SEND_ACCEPT_TERMS = 'SEND_ACCEPT_TERMS';
export const SEND_ACCEPT_TERMS_SUCCESS = 'SEND_ACCEPT_TERMS_SUCCESS';
export const SEND_ACCEPT_TERMS_ERROR = 'SEND_ACCEPT_TERMS_ERROR';

export const GET_MASSIVE_JOB = 'GET_MASSIVE_JOB';
export const GET_MASSIVE_JOB_SUCCESS = 'GET_MASSIVE_JOB_SUCCESS';
export const GET_MASSIVE_JOB_ERROR = 'GET_MASSIVE_JOB_ERROR';
export const GET_MASSIVE_JOB_ERROR_DOMAIN = 'GET_MASSIVE_JOB_ERROR_DOMAIN';

export const ACCEPTED_AND_REJECTED_TERMS_CACHE = 'ACCEPTED_AND_REJECTED_TERMS_CACHE';
export const CLEAN_ACCEPTED_AND_REJECTED_TERMS_CACHE = 'CLEAN_ACCEPTED_AND_REJECTED_TERMS_CACHE';
