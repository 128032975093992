export const POST_UPLOAD_FILES_AGENTS_SUCCESS = 'POST_UPLOAD_FILES_AGENTS_SUCCESS';
export const POST_UPLOAD_FILES_AGENTS = 'POST_UPLOAD_FILES_AGENTS';
export const POST_UPLOAD_FILES_AGENTS_ERROR = 'POST_UPLOAD_FILES_AGENTS_ERROR';
export const RESET_FORM_UPLOAD_FILES_AGENTS = 'RESET_FORM_UPLOAD_FILES_AGENTS';
export const NEX_STEP_UPLOAD_FILES_AGENTS = 'NEX_STEP_UPLOAD_FILES_AGENTS';
export const GET_EXPENSE_ACCOUNT = 'GET_EXPENC_ACCOUNT';
export const GET_EXPENSE_ACCOUNT_ERROR = 'GET_EXPENSE_ACCOUNT_ERROR';
export const GET_EXPENSE_ACCOUNT_SUCCESS = 'GET_EXPENC_ACCOUNT_SUCCESS';
export const SET_CURRENT_AGENT = 'SET_CURRENT_AGENT';
export const keyTypeFile = {
    'application/pdfs': 'pdfs',
    'text/xml': 'xmls',
    'application/zip': 'zip',
    'application/octet-stream': 'zip',
    'application/x-zip-compressed': 'zip',
    'multipart/x-zip': 'zip',
};
export const CLEAN_ERROR_PROPERTY = 'CLEAN_ERROR_PROPERTY';

export interface detailExpendAccount {
    importKeys: string;
    descriptions: string;
    amounts: string;
    taxIds: string;
    taxesIva: string;
    retentionFactor: string;
    retentions: string;
    grossAmounts: string;
    supplierNumbers: string;
    supplierName: string;
    invoiceNumber: string;
    invoiceDate: string;
    message: string;
}
